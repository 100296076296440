import { Grid, IconButton, Link, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Aos from "aos";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import images from "../../assets/images";

const Footer: React.FC = () => {
  useEffect(() => {
    Aos.init({
      duration: 700,
      once: true,
    });
  }, []);

  return (
    <>
      <footer data-aos="fade-up">
        <Container maxWidth="xl">
          {/* top start */}
          <div className="top">
            <Grid container spacing={{ lg: 8, sm: 6, xs: 5 }}>
              <Grid item lg={4} xs={12}>
                <RouterLink
                  to={`${process.env.PUBLIC_URL}/`}
                  className="pb-3"
                  title="Unique IT Solution"
                >
                  <img
                    loading="lazy"
                    src={images.Logo}
                    alt="Unique IT Solution"
                  />
                </RouterLink>
                <Typography component="p" className="company-description pb-5">
                  Visit our website to communicate and connect with us. Contact
                  us to develop unique websites
                </Typography>
                <ul className="contact-info">
                  <li>
                    <Link href="tel:+917874579457" title="+917874579457">
                      <img loading="lazy" src={images.CallPrimary} alt="Call" />
                      <Typography component="span" variant="body2">
                        +917874579457
                      </Typography>
                    </Link>
                  </li>
                  <li>
                    <Link href="tel:+12156027044" title="+917874579457">
                      <img loading="lazy" src={images.CallPrimary} alt="Call" />
                      <Typography component="span" variant="body2">
                        +1 (215) 602-7044
                      </Typography>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="mailto:info@theuniqueitsolution.com"
                      title="info@theuniqueitsolution.com"
                    >
                      <img
                        loading="lazy"
                        src={images.EmailPrimary}
                        alt="Email"
                      />
                      <Typography component="span" variant="body2">
                        info@theuniqueitsolution.com
                      </Typography>
                    </Link>
                  </li>
                  <li>
                    <img
                      loading="lazy"
                      src={images.LocationPrimary}
                      alt="Location"
                    />
                    <Typography component="span" variant="body2">
                      C-806, Titanium Business Park, Divyabhaskar Press, B/h nr.
                      Railway Under Bridge, Makarba, Ahemedabad, Gujarat 380051
                    </Typography>
                  </li>
                  <li>
                    <img
                      loading="lazy"
                      src={images.LocationPrimary}
                      alt="Location"
                    />
                    <Typography component="span" variant="body2">
                    2462 Brandon Ct,
                    Bensalem, PA- 19020
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item lg={4} sm={6}>
                <Typography component="strong" variant="subtitle1">
                  Services
                </Typography>
                <ul className="site-links">
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/services/custom-software-development`}
                      title="Custom Software Development"
                      state={{ from: "home" }}
                    >
                      Custom Software Development
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/services/work-flow-automation`}
                      title="Work Flow Automation"
                      state={{ from: "home" }}
                    >
                      Work Flow Automation
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/services/crm-and-erp-development`}
                      title="CRM & ERP Development"
                      state={{ from: "home" }}
                    >
                      CRM & ERP Development
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/services/web-development`}
                      title="Web Development"
                      state={{ from: "home" }}
                    >
                      Web Development
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/services/mobile-application-development`}
                      title="Mobile Application Development"
                      state={{ from: "home" }}
                    >
                      Mobile Application Development
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/services/ui-ux-design`}
                      title="UI/UX Design"
                      state={{ from: "home" }}
                    >
                      UI/UX Design
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/services/hire-dedicated-developers`}
                      title="Hire Dedicated Developers"
                      state={{ from: "home" }}
                    >
                      Hire Dedicated Developers
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/services/it-consulting`}
                      title="IT Consulting"
                      state={{ from: "home" }}
                    >
                      IT Consulting
                    </RouterLink>
                  </li>
                </ul>
              </Grid>
              <Grid item lg={4} sm={6}>
                <Typography component="strong" variant="subtitle1">
                  Industries
                </Typography>
                <ul className="site-links">
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/industries/hospitality-industry`}
                      title="Hospitality Industry"
                      state={{ from: "home" }}
                    >
                      Hospitality Industry
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/industries/retail-and-ecommerce-solution`}
                      title="Retail & Ecommerce Solution"
                      state={{ from: "home" }}
                    >
                      Retail & Ecommerce Solution
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/industries/news-and-entertainment`}
                      title="News & Entertainment"
                      state={{ from: "home" }}
                    >
                      News & Entertainment
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/industries/production-and-service-assurance`}
                      title="Production & Service Assurance"
                      state={{ from: "home" }}
                    >
                      Production & Service Assurance
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/industries/telecommunication-media`}
                      title="Telecommunication Media"
                      state={{ from: "home" }}
                    >
                      Telecommunication Media
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/industries/logistic-and-distribution`}
                      title="Logistic & Distribution"
                      state={{ from: "home" }}
                    >
                      Logistic & Distribution
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      to={`${process.env.PUBLIC_URL}/industries/food-and-beverages`}
                      title="Food & Beverages"
                      state={{ from: "home" }}
                    >
                      Food & Beverages
                    </RouterLink>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>
          {/* top end */}
        </Container>
        <hr />
        <Container maxWidth="xl">
          {/* bottom start */}
          <div className="bottom">
            <Typography component="small">
              © 2020 Unique It Solution. All rights reserved
            </Typography>
            <div className="social-links " style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <IconButton size="small" title="Facebook">
                <img loading="lazy" src={images.Facebook} alt="Facebook" />
                <img
                  src={images.FacebookWhite}
                  className="active"
                  alt="Facebook"
                  loading="lazy"
                />
              </IconButton> */}
              {/* <IconButton size="small" title="Twitter">
                <img loading="lazy" src={images.X} alt="Twitter" />
                <img
                  src={images.XWhite}
                  className="active"
                  alt="Twitter"
                  loading="lazy"
                />
              </IconButton> */}
              <a
                href="https://www.instagram.com/unique_it_solutions?igsh=MWUzZm43aThvaXR6Ng=="
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }} // Optional: removes underline from the link
              >
                <IconButton size="small" title="Instagram">
                  <img loading="lazy" src={images.Instagram} alt="Instagram" />
                  <img
                    src={images.InstagramWhite}
                    className="active"
                    alt="Instagram"
                    loading="lazy"

                  />
                </IconButton>
              </a>
              <a
                href="https://www.linkedin.com/company/unique-it-solution/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }} // Optional: removes underline from the link
              >
                <IconButton size="small" title="LinkedIn" >
                  <img src={images.Linkedin} alt="LinkedIn" loading="lazy" />
                  <img
                    src={images.LinkedinWhite}
                    className="active"
                    alt="LinkedIn"
                    loading="lazy"
                  />
                </IconButton>
              </a>
            </div>
          </div>
          {/* bottom end */}
        </Container>
      </footer>
    </>
  );
};

export default Footer;
