import {
  Button,
  Container,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,

  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";
import FileUpload from "../../components/FileUpload";
import PageLoader from "../../components/PageLoader";
import { CircularProgress } from "@mui/material";
import Toast from 'typescript-toastify';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs: React.FC = () => {
  // const apiUrl = 'https://api.theuniqueitsolution.com/mail/Api/sendmailapi.php';
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    company: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleInputChange = (e: { target: { id: any; value: any; }; }) => {
    const { id, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleFileChange = (file: File | null) => {
    setFile(file);
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setLoading(true);
    if (!formData.name || !formData.email || !formData.phone || !formData.description) {
      new Toast({
        position: "top-right",
        toastMsg: "Please fill in all required fields.",
        autoCloseTime: 5000,
        canClose: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        type: "error",
        theme: "dark",
      });
      setLoading(false);
      return;
    }

    const emailData = new FormData();
    
    // if (file) {
    //   emailData.append("File", file);
    // } else {
    //   console.log("No file selected");
    //   // Handle the case when no file is selected (e.g., show a message to the user)
    // }
    emailData.append("Subject", "Contact Us inquiry from Unique IT Solution Website ");
    emailData.append("Body", `<b>Name:</b> ${formData.name} <br/>
      <b>Phone:</b> ${formData.phone} <br/>
      <b>Email:</b> ${formData.email} <br/>
      <b>Company:</b> ${formData.company} <br/>
      <b>Message:</b> ${formData.description}`);

    if (file) {
      emailData.append("file", file);
    }
    try {
      const response = await axios.post(
        'https://api.theuniqueitsolution.com/mail/Api/sendmailforuniqueitwebsite.php',
        emailData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const result = response.data;

      if (result.status) {
        toast.success("Message Sent Successfully", {
          position: "top-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        setFormData({  name: "",
          email: "",
          subject: "",
          phone: "",
          company: "",
          description: "",})
        setLoading(false);
      } else {
        toast.error("Error sending email. Please try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        setLoading(false);
         console.log('API Response Error:', result);  // Log the specific error returned by the API
      }
    } catch (error: any) {
      toast.error("Failed to send email. Check console for details.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      setLoading(false);
      console.error('Error:', error.response ? error.response.data : error.message);  // Improved error logging
    }
  };
  const faqDataPage = [
    {
      id: "panel1",
      question: "How can I get in touch with Unique IT Solution?",
      answer: "You can reach out to us through the Contact Us page by filling out the contact form with your details. Our team will get back to you promptly. Alternatively, you can also email us directly at info@uniqueitsolution.com or call us at our provided contact number.",
    },
    {
      id: "panel2",
      question: "What information is required to contact Unique IT Solution?",
      answer: "You’ll need to provide basic contact information like your name, email address, and phone number. If you're representing a company, be sure to mention your company name. Giving us a detailed description of your project or concern will help us provide you with tailored solutions quickly."
    },
    {
      id: "panel3",
      question: "What services can I inquire about through the Contact Us page?",
      answer: "You can reach out to inquire about a variety of services provided by Unique IT Solution, including but not limited to Custom Software Development, Web Development, Mobile Application Development, and Workflow Automation. No matter your technical challenge, we're here to help you find the right solution."
    },
    {
      id: "panel4",
      question: "What should I expect after contacting Unique IT Solution?",
      answer: "After submitting your inquiry, a representative from Unique IT Solution will reach out to gather more details, clarify your requirements, and discuss the best solution for your project. We aim to provide clear communication and guidance at every step."
    },
    {
      id: "panel5",
      question: "Does Unique IT Solution offer support after project completion?",
      answer: " Absolutely. Unique IT Solution provides ongoing support and maintenance services after project delivery. Whether it’s for Web Development, Mobile App Development, or Custom Software Solutions, we ensure long-term success for your projects."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Contact Us | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner page-banner mb-9"
        style={{ backgroundImage: `url(${images.ContactUsBanner})` }}
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
              className="pb-3"
            >
              Contact Us
            </Typography>
            <Typography
              component="p"
              variant="body1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Learn about our mission, values, and the team dedicated to
              delivering exceptional technology solutions for your business
            </Typography>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        <section className="contact-us-form-wrapper mb-9">
          <Grid container spacing={6}>
            <Grid
              item
              lg={6}
              xs={12}
              order={{ lg: 1, xs: 2 }}
            >
              {/* Contact Info */}
              <div className="contact-info">
                <ul>
                  <li>
                    <a href="tel:+917874579457" title="+917874579457">
                      <img loading="lazy" src={images.CallPrimary} alt="Call" />
                      <Typography component="span" variant="body2">
                        +917874579457
                      </Typography>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+12156027044" title="+917874579457">
                      <img loading="lazy" src={images.CallPrimary} alt="Call" />
                      <Typography component="span" variant="body2">
                        +1 (215) 602-7044
                      </Typography>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@theuniqueitsolution.com" title="info@theuniqueitsolution.com">
                      <img loading="lazy" src={images.EmailPrimary} alt="Email" />
                      <Typography component="span" variant="body2">
                        info@theuniqueitsolution.com
                      </Typography>
                    </a>
                  </li>
                  <li>
                    <img loading="lazy" src={images.LocationPrimary} alt="Location" />
                    <Typography component="span" variant="body2">
                      Titanium Business Park, C-806, Ahemedabad, Gujarat 380051
                    </Typography>
                  </li>
                  <li>
                    <img loading="lazy" src={images.LocationPrimary} alt="Location" />
                    <Typography component="span" variant="body2">
                    2462 Brandon Ct,
                    Bensalem, PA- 19020
                    </Typography>
                  </li>
                </ul>
                <img loading="lazy" src={images.ContactUs} alt="Contact Us" />
              </div>
            </Grid>

            <Grid
              item
              lg={6}
              xs={12}
              order={{ lg: 2, xs: 1 }}
            >
              {/* Contact Form */}
              <form onSubmit={handleSubmit}>
                <Typography component="h3" variant="h3" className="pb-4">
                  Get in Touch
                </Typography>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <FilledInput
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <img loading="lazy" src={images.User} alt="User" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <FilledInput
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <img loading="lazy" src={images.Email} alt="Email" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor="subject">Subject</InputLabel>
                      <FilledInput
                        id="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor="phone">Phone</InputLabel>
                      <FilledInput
                        id="phone"
                        type="number"
                        value={formData.phone}
                        onChange={handleInputChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <img loading="lazy" src={images.Call} alt="Phone" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor="company">Company Name</InputLabel>
                      <FilledInput
                        id="company"
                        value={formData.company}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor="description">Description</InputLabel>
                      <FilledInput
                        id="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        multiline
                        rows={3}
                      />
                    </FormControl>
                  </Grid><Grid item xs={12}>

                    <FileUpload onFileChange={handleFileChange} />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      title="Send Message"
                      endIcon={<img loading="lazy" src={images.RightArrowWhite} alt="Right Arrow" />}
                    >
                      <span className="text">Send Message</span>
                    </Button>        {loading ? <CircularProgress id="contactusloader" />  :  <div></div>}

                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </section>
      </Container>


      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
      <ToastContainer />
    </>
  );
};

export default ContactUs;

