import { Button, Container, Grid, Typography } from "@mui/material";
import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

const Technologies: React.FC = () => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "How do you choose which technology to use for a project?",
      answer: "The choice of technology depends on several factors including the project's requirements, scalability needs, and the client's preferences. We evaluate each project to determine the best combination of technologies that will provide the most effective and efficient solution.",
    },
    {
      id: "panel2",
      question: "What is the benefit of selecting right technology for your project.",
      answer: "Selecting the right technology ensures your project is efficient, scalable, and aligns with your goals. It enhances performance, improves user experience, and reduces long-term costs by minimizing maintenance issues. The right technology also speeds up development, integrates well with existing systems, and offers flexibility for future updates, ultimately supporting your business objectives effectively."
    },
    {
      id: "panel3",
      question: "How do you handle technology updates and upgrades.",
      answer: "We monitor for updates and apply them carefully to maintain system performance and stability. We also notify you about any important changes that might impact your system."
    },
    {
      id: "panel4",
      question: "Do you offer support and maintenance for the technologies you use?",
      answer: " Yes, we provide ongoing support and maintenance for all technologies we use. This includes fixing issues, applying updates, and making improvements to keep your software running smoothly"
    },
    {
      id: "panel5",
      question: "Can you provide examples of successful projects using your technologies?",
      answer: "Yes, we can provide case studies and examples of successful projects that showcase how we have used various technologies to achieve client goals. These examples highlight our expertise and the positive outcomes we have delivered through our technology solutions."
    },
  ]
  useEffect(() => {
    Aos.init({
      duration: 700,
      once: true,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Technologies | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner page-banner mb-9"
        style={{ backgroundImage: `url(${images.TechnologiesBanner})` }}
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
              className="pb-3"
            >
              Technologies
            </Typography>
            <Typography
              component="p"
              variant="body1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Transform your business with our holistic approach to innovation.
              We enhance backend efficiency, create elegant user-centric UI/UX
              designs, develop innovative mobile apps, and offer seamless cloud
              solutions to drive operational excellence and strategic growth.
            </Typography>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        {/* technologies content start */}
        <section className="technologies-content">
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h3"
                  variant="h3"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Frontend
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  At Unique IT Solution, we harness a mix of technologies to
                  build compelling websites and applications. We employ Angular
                  and React.js for creating interactive and responsive
                  experiences, ensuring seamless user interaction. Our design
                  prowess extends with HTML, CSS, jQuery, MDB (Material Design
                  for Bootstrap), and MUI (Material-UI), enabling us to craft
                  visually appealing layouts and styles that function flawlessly
                  across devices.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.FrontendTechnologies}
                  alt="Frontend Technologies"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.BackendTechnologies}
                  alt="Backend Technologies"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h3"
                  variant="h3"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Backend
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We specialize in building robust backend systems with
                  cutting-edge technologies such as Node.js for scalable network
                  applications, .NET and C# for secure enterprise-level
                  solutions, PHP with Laravel for dynamic web development, and
                  Java for reliable, large-scale applications.
                  <br />
                  <br />
                  Our solutions ensure smooth operation and efficiency, designed
                  with scalability, security, and reliability to meet your
                  specific requirements. We prioritize seamless integration with
                  your systems, offering extensive functionality and flexibility
                  through meticulously crafted APIs.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h3"
                  variant="h3"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Database
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We use a range of cutting-edge technologies to ensure precise
                  and efficient data handling, including PostgreSQL, Microsoft
                  SQL Server (MSSQL), MySQL, MongoDB, and Firebase. PostgreSQL
                  offers advanced features for complex applications, while MSSQL
                  provides robust data management. MySQL is praised for its
                  scalability, MongoDB excels with unstructured data, and
                  Firebase offers real-time capabilities for mobile apps.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.DatabaseTechnologies}
                  alt="Database technologies"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.MobileApplicationDevelopmentTechnologies}
                  alt="Mobile Application Development Technologies"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h3"
                  variant="h3"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Mobile Application Development
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We specialize in mobile app development, prioritizing Flutter
                  for high-performance applications with Dart for cross-platform
                  compatibility. Our expertise includes JavaScript for
                  responsive UI in React Native and web apps, integrating
                  plugins for enhanced functionality, and delivering scalable,
                  secure, and optimized solutions, including iOS development.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h3"
                  variant="h3"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Cloud/DevOps
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We utilize AWS and Azure for scalable, secure cloud solutions,
                  ensuring optimized infrastructure and seamless integration
                  using tools like Terraform, Kubernetes, and Jenkins. These
                  tools enable us to efficiently manage and deploy cloud
                  resources, enhancing the performance and reliability of our
                  solutions.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.CloudTechnologies}
                  alt="Cloud Technologies"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.UIUXTechnologies}
                  alt="UI/UX Designing Technologies"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h3"
                  variant="h3"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  UI/UX Designing
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  At Unique IT Solution, our UI/UX design adheres to all
                  principles and best practices of user interface and user
                  experience design. We prioritize seamless user interaction,
                  visually appealing layouts, and optimized functionality to
                  ensure a superior user experience across all our projects.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </section>
        {/* technologies content end */}

        {/* visit our portfolio start */}
        <section className="visit-our-portfolio mb-9" data-aos="fade-in">
          <img
            src={images.OvalLeft}
            className="oval-one"
            alt="Oval"
            data-aos="fade-in"
          />
          <img
            src={images.OvalLeft}
            className="oval-two"
            alt="Oval"
            data-aos="fade-in"
          />
          <img
            src={images.OvalLeft}
            className="oval-three"
            alt="Oval"
            data-aos="fade-in"
          />
          <img
            src={images.GridBG}
            className="grid-bg"
            alt="Grid"
            data-aos="fade-in"
          />
          <Typography component="h4" variant="h4" data-aos="fade-up">
            Visit our portfolio to see how we are utilizing these technologies
            to fulfill your business requirements.
          </Typography>
          {/* <Link to={`${process.env.PUBLIC_URL}/portfolio`}>
            <Button
              variant="contained"
              title="View Portfolio"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <span className="text">View Portfolio</span>
            </Button>
          </Link> */}
          <img
            src={images.VisitOurPortfolio}
            className="section-img"
            alt="Laptop & Mobile"
            data-aos="fade-up"
            data-aos-delay="200"
          />
        </section>
        {/* visit our portfolio end */}
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default Technologies;
