import React from "react";
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}
// interface FAQItem {
//   id: string;
//   question: string;
//   answer: string;
//   link?: string;
// }
const WebDevelopment: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What is web development?",
      answer: "Web development is the process of building websites or web applications that people can use online. It includes front-end development, which focuses on the look and feel of the site, and back-end development, which handles the server and database.The goal of web development is to create fast, responsive websites that work well on all devices",
    },
    {
      id: "panel2",
      question: "What are the benefits of having a custom-built website?",
      answer: "A custom website is designed specifically to meet your business needs and goals.You can incorporate any functionality you need, whether it’s advanced e-commerce features, complex user interactions, or integration with other systems, all built to your exact specifications."
    },
    {
      id: "panel3",
      question: "What kind of web application have you developed?",
      answer: "Over the years of our development experience, we have had the privilege of working and providing tailored solutions into various industries like Hospitality, Retali and Ecommerce,News & Entertainment, Telecomunication Media and many more.",
      link: [
        "https://ahmedabadmedia.in/",
        "https://dicasaceramica.com/",
        "https://theasianfresh.com/",
        "https://slovetiles.com/",
      ],


    },
    {
      id: "panel4",
      question: "How do you ensure the security of my website?",
      answer: " We write code with security in mind and implement industry-standard security practices such as SSL encryption, regular updates, secure coding practices, and vulnerability assessments to protect your website from potential threats."

    },
    {
      id: "panel5",
      question: "What is the cost of web development?",
      answer: "The cost of web development can vary greatly depending on several key factors.One of the most significant is the size of the website.A simple, single- page site will generally be more budget - friendly compared to a multi - page business site or an extensive e - commerce platform.The more pages and content required, the higher the cost will likely be."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Web Development | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner service-details-banner bg-light-pink"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <div className="left">
              <Breadcrumbs
                separator={
                  <img
                    loading="lazy"
                    src={images.CaretRight}
                    alt="Caret Right"
                  />
                }
                data-aos="fade-up"
              >
                <Link to={`/${link}`} title={link}>
                  {link.charAt(0).toUpperCase() + link.slice(1)}
                </Link>
                <Typography>Web Development</Typography>
              </Breadcrumbs>
              <Typography
                component="h2"
                variant="h2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Web Development
              </Typography>
              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                <Button
                  variant="contained"
                  color="primary"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  title="Get A Quote"
                >
                  <span className="text">Get A Quote</span>
                </Button>
              </Link>
            </div>
            <div className="right" data-aos="fade-up">
              <img
                loading="lazy"
                src={images.WebDevelopmentBannerImage}
                alt="Web Development"
                width="483"
                height="473"
              />
            </div>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        <section className="service-details-content">
          <Typography component="p" variant="body1" data-aos="fade-up">
            At unique it solution, we provide complete web development services
            aimed at developing strong, user-friendly, and eye-catching websites
            that match your business needs. Our solutions are designed to
            increase your online presence for the purpose of driving growth
            while achieving your goals with less time and resource outlay.
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            align="center"
            data-aos="fade-up"
            className="pb-3"
          >
            How We Build Web Apps
          </Typography>
          <Typography
            component="p"
            align="center"
            variant="body1"
            data-aos="fade-up"
            className="pb-6"
          >
            Firstly, we try to understand your business requirements by getting
            details from your team. We start by sketching on paper so that the
            initial ideas correspond with what you envision happening. Experts
            develop a customized website based on this information, which is
            then utilized for further refining. This involves creating several
            versions of the website and testing it during each stage to ensure
            that it meets customer needs, including their perspectives about
            what they want. After the website has been built, we launch it
            without any difficulties as well as continue supporting its smooth
            operation until it goes down.
          </Typography>
          <Typography
            component="span"
            variant="h6"
            align="center"
            color="primary"
            data-aos="fade-up"
            data-aos-delay="100"
            className="pb-7"
            style={{ display: "block" }}
          >
            Step-by-Step Process for Building your Web App
          </Typography>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.RequirementGathering}
                  alt="Conceptualization and Requirements Gathering"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Conceptualization and Requirements Gathering
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  The first step is making sketches on paper borders as an aid
                  in visual brainstorming. It helps us develop our concept
                  perfectly before going digital.
                  <br />
                  <br />
                  Our team engages your stakeholders intensively to collect
                  complete requirements. This way we grasp issues of concern
                  about your company.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  User Interface Design and Prototyping
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  This process emphasizes interactive activities and
                  high-quality user interfaces, converting conceptualized ideas
                  into detailed UI designs.
                  <br />
                  <br />
                  Prototypes are made to better understand user flow in terms of
                  aesthetics and usability standards concerning branding.
                  <br />
                  <br />
                  Collaboration with your team ensures that branding is
                  reflected toward modern design trends while considering users’
                  preferences, too.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.Prototyping}
                  alt="User Interface Design and Prototyping"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.DevelopmentAndTesting}
                  alt="Development and Iterative Testing"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Development and Iterative Testing
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  An iterative approach is followed when building the web app.
                  Each web design stage is properly tested to ensure that it
                  improves functionality, performance, and user experience in a
                  step-by-step process. The objective is to optimize the website
                  regarding visibility and content structure and improve
                  metadata effectiveness. Feedback from testing is actively
                  incorporated to refine and enrich the app's features, aligning
                  with SEO strategies to maximize online presence and
                  engagement.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Quality Assurance and Integration
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  For us, high standards are key based on our testing and
                  quality assurance processes, which guarantee the reliability
                  of the web app and its smooth operation.
                  <br />
                  <br />
                  We maintain data integrity while integrating with your
                  existing systems to provide a unified user experience across
                  platforms.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.QualityAssurance}
                  alt="Quality Assurance and Integration"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.DeploymentSupport}
                  alt="Deployment, Support, and Continuous Improvement"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Deployment, Support, and Continuous Improvement
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  After finishing the development process and testing phases,
                  managing deployment for a timely and efficient launch is
                  relatively easy.
                  <br />
                  <br />
                  Our team provides continuous support after launching. Thus, we
                  will just not create and give it to you, but be with you to
                  maintain the system and address any emergency issues.
                  <br />
                  <br />
                  Our aim is that regular updates will keep your web app
                  up-to-date by incorporating new features and enhancements to
                  meet evolving user needs and technological advances.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default WebDevelopment;
