// src/components/Layout.tsx
import { IconButton } from "@mui/material";
import React, { ReactNode, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import images from "../../assets/images";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageLoader from "../components/PageLoader";
import TawkWidget from "../components/TawkWidget";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [pageLoading, setPageLoading] = useState(false);
  const location = useLocation();
  const isFirstRender = useRef(true);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    const totalHeight = document.documentElement.scrollHeight;
    const duration = 1000;
    const intervalTime = 10;

    const totalScrollSteps = duration / intervalTime;
    const scrollStep = totalHeight / totalScrollSteps;

    const scrollInterval = setInterval(() => {
      if (window.pageYOffset > 0) {
        window.scrollBy(0, -scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, intervalTime);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setPageLoading(true);

    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1000);

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, [location]);

  return (
    <>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <>
          {/* header start */}
          <Header />
          {/* header end */}

          {/* wrapper start */}
          <div className="wrapper">
            <main>{children}</main>
          </div>
          {/* wrapper start */}

          {/* footer start */}
          <Footer />
          {/* footer end */}

          <IconButton
            className={`scroll-to-top-btn ${isVisible ? "show" : ""}`}
            size="small"
            color="primary"
            onClick={scrollToTop}
            title="Scroll to Top"
          >
            <img src={images.ArrowUp} alt="Arrow Up" />
          </IconButton>

          <TawkWidget />
        </>
      )}
    </>
  );
};

export default Layout;
