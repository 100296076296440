import { Button, Container, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Aos from "aos";
import React, { useEffect } from "react";
import images from "../../assets/images";

const RequestProposal: React.FC = () => {
  useEffect(() => {
    Aos.init({
      duration: 700,
      once: true,
    });
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <section className="request-proposal" data-aos="fade-in">
          <img
            src={images.OvalLeft}
            alt="Oval"
            className="oval-left"
            data-aos="fade-in"
          />
          <img
            src={images.OvalRight}
            alt="Oval"
            className="oval-right"
            data-aos="fade-in"
          />
          <Typography
            component="h3"
            variant="h3"
            className="pb-4"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Ready to build your custom application solution
          </Typography>
          <Typography
            component="span"
            variant="subtitle2"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Send us your requirements on:
          </Typography>
          <ul data-aos="fade-up" data-aos-delay="300">
            <li>
              <Link href="mailto:info@theuniqueitsolution.com">
                <img loading="lazy" src={images.EmailWhite} alt="Email" />
                <Typography component="span" variant="subtitle1">
                  info@theuniqueitsolution.com
                </Typography>
              </Link>
            </li>
            <li>
              <Typography component="small">OR</Typography>
            </li>
            <li>
              <Link href="tel:+917874579457">
                <img loading="lazy" src={images.CallWhite} alt="Call" />
                <Typography component="span" variant="subtitle1">
                  +917874579457
                </Typography>
              </Link>
            </li>
            <li>
              <Typography component="small">OR</Typography>
            </li>
            <li>
              <Link href="tel:+12156027044" title="+917874579457">
                <img loading="lazy" src={images.CallWhite} alt="Call" />
                <Typography component="span" variant="subtitle1">
                  +1 (215) 602-7044
                </Typography>
              </Link>
            </li>
          </ul>
          <RouterLink to={`${process.env.PUBLIC_URL}/contact-us`}>
            <Button
              variant="contained"
              title="Request a Proposal"
              color="inherit"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <span className="text">Request a Proposal</span>
            </Button>
          </RouterLink>
        </section>
      </Container>
    </>
  );
};

export default RequestProposal;
