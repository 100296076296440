import { Container, Grid, Typography } from "@mui/material";
import Aos from "aos";
import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import images from "../../../assets/images";
// import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

const AboutUs: React.FC = () => {
  const [startCounter, setStartCounter] = useState(false);

  // const faqDataPage = [
  //   {
  //     id: "panel1",
  //     question: "Why choose unique IT solution as service provider?",
  //     answer: "Unique IT Solution delivers custom, cutting-edge technology solutions.",
  //   },
  //   {
  //     id: "panel2",
  //     question :"Which technology we use for development?",
  //     answer :" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
  //   },
  //   {
  //     id: "panel3",
  //     question :"How would you provide custom software development?",
  //     answer :" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
  //   },
  //   {
  //     id: "panel4",
  //     question :"What about maintence?",
  //     answer :" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
  //   },
  //   {
  //     id: "panel5",
  //     question :"How to contact us?",
  //     answer :" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
  //   },
  // ]

  const handleStartCounter = () => {
    setStartCounter(true);
  };

  useEffect(() => {
    Aos.init({
      duration: 700,
      once: true,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us | Unique IT Solution</title>
        <meta name="description" content="Learn more about Unique IT Solution, our mission, values, and the team behind our innovative solutions." />
        <meta name="keywords" content="About Us, Unique IT Solution, company information, mission, team" />
        <meta property="og:title" content="About Us | Unique IT Solution" />
        <meta property="og:description" content="Learn more about Unique IT Solution, our mission, values, and the team behind our innovative solutions." />
        <meta property="og:image" content="URL-to-an-image-representing-your-company" />
        <meta property="og:url" content="https://www.yourwebsite.com/about-us" />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* banner start */}
      <section
        className="banner page-banner mb-9"
        style={{ backgroundImage: `url(${images.AboutUsBanner})` }}
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
              className="pb-3"
            >
              Who We Are
            </Typography>
            <Typography
              component="p"
              variant="body1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Learn about our mission, values, and the team dedicated to
              delivering exceptional technology solutions for your business
            </Typography>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        {/* about it solution start */}
        <section className="about-it-solution mb-9">
          <Grid container spacing={{ xl: 8, xs: 5 }}>
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <Typography
                component="h3"
                variant="h3"
                className="pb-4"
                data-aos="fade-up"
              >
                About IT Solutions That are Unique
              </Typography>
              <Typography component="p" variant="body1" data-aos="fade-up">
                Established in 2020, Unique IT Solution has grown with
                passionate members. We are dedicated to delivering tailored IT
                solutions to drive client growth and transformation. <br />
                <br />
                Our expertise in developing Custom Web and Mobile Applications 
                tailored to meet your specific needs, incorporating ERP and CRM solutions for enhanced business efficiency. we
                excel in creating bespoke products that meet specific
                requirements and keep our clients ahead.
                <br />
                <br /> Our support teams ensure continuous client success by
                offering exclusive after-sales services that go beyond
                delivering perfect products.
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              {/* counter start */}
              <Waypoint onEnter={handleStartCounter} />
              <section className="vertical-counter">
                <ul>
                  <li data-aos="fade-up">
                    {startCounter ? (
                      <CountUp
                        start={0}
                        end={40}
                        duration={3}
                        suffix="%"
                        startOnMount={false}
                      />
                    ) : (
                      <span>0</span>
                    )}
                    <Typography component="p" variant="body1">
                    Client Retention Score
                    </Typography>
                  </li>
                  {/* <li data-aos="fade-up" data-aos-delay="100">
                    {startCounter ? (
                      <CountUp
                        start={0}
                        end={20}
                        duration={3}
                        suffix="+"
                        startOnMount={false}
                      />
                    ) : (
                      <span>0</span>
                    )}
                    <Typography component="p" variant="body1">
                      Experience Developers
                    </Typography>
                  </li> */}
                </ul>
                <ul>
                  <li data-aos="fade-up" data-aos-delay="200">
                    {startCounter ? (
                      <CountUp
                        start={0}
                        end={100}
                        duration={3}
                        suffix="+"
                        startOnMount={false}
                      />
                    ) : (
                      <span>0</span>
                    )}
                    <Typography component="p" variant="body1">
                      Projects Completed
                    </Typography>
                  </li>
                  <li data-aos="fade-up">
                    {startCounter ? (
                      <CountUp
                        start={0}
                        end={15}
                        duration={3}
                        suffix="+"
                        startOnMount={false}
                      />
                    ) : (
                      <span>0</span>
                    )}
                    <Typography component="p" variant="body1">
                      Industries We Serve
                    </Typography>
                  </li>
                </ul>
                <ul>
                  <li data-aos="fade-up">
                    {startCounter ? (
                      <CountUp
                        start={0}
                        end={5}
                        duration={3}
                        suffix="+"
                        startOnMount={false}
                      />
                    ) : (
                      <span>0</span>
                    )}
                    <Typography component="p" variant="body1">
                      Years of Experience
                    </Typography>
                  </li>
                </ul>
              </section>
              {/* counter end */}
            </Grid>
          </Grid>
        </section>
        {/* about it solution end */}

        <section className="vision-and-mission">
          <Typography
            component="h3"
            align="center"
            variant="h3"
            className="pb-3"
            data-aos="fade-up"
          >
            Our Vision & Mission
          </Typography>
          <Typography
            component="p"
            align="center"
            variant="body1"
            className="pb-7"
            data-aos="fade-up"
          >
            Discover our commitment to driving innovation and excellence, on
            how we aim to transform businesses through cutting-edge technology
            solutions.
          </Typography>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.EmpoweringDigitalTransformation}
                  alt="Empowering Digital Transformation"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Empowering Digital Transformation
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We leverage cutting-edge technologies to revolutionize your
                  business operations. Our advanced solutions streamline
                  processes and drive innovation, ensuring your business stays
                  ahead in the digital age. By integrating these transformative
                  technologies, we empower your business to thrive and excel.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Code that Power Business Growth
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Our meticulously crafted software solutions are designed to
                  propel business growth with scalable, efficient code. We focus
                  on optimizing operations, enhancing productivity, and
                  establishing a resilient digital foundation that supports
                  sustainable expansion and innovation.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.PowerBusinessGrowth}
                  alt="Code that Power Business Growth"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.EnhancingOnlineVisibility}
                  alt="Code that Power Business Growth"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Enhancing Online Visibility
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We specialize in strategies that amplify your online presence,
                  ensuring your business stands out in a competitive digital
                  landscape. Through targeted SEO, compelling content, and
                  strategic digital marketing, we help you reach and engage your
                  audience effectively.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
      {/* FAQ start */}

      {/* <FAQ faqData={faqDataPage} /> */}
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default AboutUs;
