import { useEffect } from "react";

export const TawkWidget = () => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://embed.tawk.to/67931682825083258e0a5d6e/1iib8vs7v";
        script.async = true;
        script.charset = "UTF-8";
        script.setAttribute("crossorigin", "*");
        document.body.appendChild(script);

        return () => {
            // Cleanup: Remove script if the component unmounts
            document.body.removeChild(script);
        };
    }, []);

  return null;
}

export default TawkWidget;
